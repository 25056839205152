@media  only screen and (min-width : 120px) {
  .custom-container {
    width: 50vw !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem !important;
    padding: .75rem 1.25rem;
    background-color: #ffffff;
    /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);*/
    margin-bottom: 30px;
  }


  body {
  padding-top: 90px;
  background-color:  #6767ab !important;
  }

}

.logocolortext {
  color: white !important;
}


.hltb-container {

padding-top: 10px;

 
    color: white !important;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.price-container {
  width:  100%;
}


.badge-danger {
    color:  black !important;
    background-color: white !important;
}

.badge-info {
    color:  black !important;
    background-color: #f0e9f8 !important;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}



.ml-2, .mx-2 {
    margin-left: 0.5rem !important;
}

.btn {
  white-space: nowrap;
}

.btn .badge {
    position: relative;
    top: -1px;
}


.btn-block {
    display: block;
    width: 100%;
}

.btn{
  font-size: 0.9rem !important;
}

.btn-secondary {
    color: white !important;
    background-color: #5a33ff !important;
    border-color: #5a33ff !important;
}

p {
   display:block !important;
}

.logonin.eshop {
    background-image: url(logo-eshop.svg);
    max-width: 12em;
  }

.shopee {
    background-image: url(Shopee2.svg);
    max-width: 12em;
  }

.logonin.psstore {
    background-image: url(playstationstore.svg);
    max-width: 12em;
  }

.logonen {
    max-width: 10em;
    max-height: 2.8em;
    height: 2.8em;
    width: 8em;
    background-size: contain;
    background-position: left;
    background-attachment: scroll;
    background-color: transparent;
    background-repeat: no-repeat;
  }


.logonin {
    max-width: 10em;
    max-height: 2.8em;
    height: 2.8em;
    width: 8em;
    background-size: contain;
    background-position: left;
    background-attachment: scroll;
    background-color: transparent;
    background-repeat: no-repeat;
  }

img {
    vertical-align: middle;
    border-style: none;
}

.item-price-table {
    margin-bottom: 0;
}

.text-right {
    text-align: right !important;
}

.item-price-table td {
    padding: 0 !important;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table-align-middle td {
    vertical-align: middle;
}

.item-price-table .has-notes td a {
    padding-bottom: 0.25rem;
}

.pb-1, .py-1 {
    padding-bottom: 0.25rem !important;
}

table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

table {
    border-collapse: collapse;
}

a {
/*    color: #000000 !important;*/
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects !important;
}

a.giftcardlink {
    color: inherit !important; /* Inherit the color from the parent */
    text-decoration: none !important; /* Remove default underline */
    position: relative; /* Set position relative for pseudo-element */
}

a.giftcardlink::after {
    content: ""; /* Add empty content */
    position: absolute; /* Position the pseudo-element */
    left: 0; /* Align the pseudo-element with the left edge of the link */
    bottom: -2px; /* Move the pseudo-element below the link */
    width: 100%; /* Make the pseudo-element cover the entire width of the link */
    border-bottom: 1px solid #9c27b0; /* Set the color of the underline */
}

a.origprice {
    color: #ffffff !important;
    font-weight:  bold;
    text-decoration: underline !important;
}

a.infotax {
    color: #0000EE !important;
    white-space: nowrap !important;
    text-decoration: underline !important;
}

.text-dark, .watch-button.watched:not(:hover) .text {
    color: #343a40 !important;
}


.dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    background-color: #9c27b0 !important;
}

.item-price-table td a {
    padding: 0.75rem;
    display: block;
}

.priceRows {
    padding: 0.75rem;
    display: block;
}

/* Specific styles for the link inside .no-style-link */
.item-price-table td a.no-style-link {
  padding: 0;  /* Remove the padding for this specific link */
  display: inline-flex;  /* Use inline-flex for layout */
  line-height: 0;         /* Prevent vertical space */
}

.nopaddingA {
     padding-left: 0 !important;
    padding-right:  0 !important;
    padding-bottom: 0.1rem !important;
    padding-top: 0.1rem !important;
    display: block;
}

.item-price-table .has-notes td a {
    padding-bottom: 0.25rem;
}

.item-table-best {
    background-color: #f0e9f8;
  }


  .item-price-table td.version {
    font-size: 0.8rem;
    /*border:  none;*/
}

table tr:first-child td:first-child {
    border-top-left-radius: 10px;
    border-color: white;
    /*border-bottom-left-radius: 10px;*/
    /*border:  none;*/
}

table tr:first-child td:nth-child(2) {
    /*border-top-right-radius: 10px;*/
    border-color: white;
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:first-child td:last-child {
    border-top-right-radius: 10px;
    border-color: white;
    /*border-bottom-right-radius: 10px; #f0e9f8*/
    /*border: none;*/
}


table tr:nth-child(2) td:first-child {
    
    border-color: white;
    /*border-bottom-width: thick;*/
    /*border-bottom-left-radius: 10px;*/
    /*border:  10;*/
}

table tr:nth-child(2) td:nth-child(2) {
    
    border-color: white;

    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(2) td:last-child {
    
    border-color: white;
      /*border-bottom-width: thick;*/
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(3) td:first-child {
    
    border-color: white;
    /*border-bottom-left-radius: 10px;*/
    /*border:  none;*/
}

table tr:nth-child(3) td:nth-child(2) {
    
    border-color: white;
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(3) td:last-child {
    
    border-color: white;
    /*border-bottom-width: 10px;*/
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(4) td:first-child {
    
    border-color: white;
    /*border-bottom-left-radius: 10px;*/
    /*border:  none;*/
}

table tr:nth-child(4) td:nth-child(2) {
    
    border-color: white;
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(4) td:last-child {
    
    border-color: white;
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(5) td:first-child {
    
    border-color: white;
    /*border-bottom-left-radius: 10px;*/
    /*border:  none;*/
}

table tr:nth-child(5) td:nth-child(2) {
    
    border-color: white;
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}

table tr:nth-child(5) td:last-child {
    
    border-color: white;
    /*border-bottom-right-radius: 10px;*/
    /*border: none;*/
}



table tr:last-child td:first-child {
    /*border-top-left-radius: 10px;*/
    border-bottom-left-radius: 10px;
    border:  none;
}

table tr:last-child td:nth-child(2) {
    /*border-top-right-radius: 10px;*/
    /*border-bottom-right-radius: 10px;*/
    border: none;
}

table tr:last-child td:last-child {
    /*border-top-right-radius: 10px;*/
    border-bottom-right-radius: 10px;
    border: none;
}


.ads {
   height: 90px;
   /*background-size: 100%;*/
   background-position: center;
   background-image: url('https://img.involve.asia/rpss/campaigns_banners/1610939924-Eor7UR1fY7PRiMAotSjQLhmtYev6of7H.jpg');
   background-repeat: no-repeat;
   display: block;
}

.psplusbadge
{
background-size:  15px;
background-position:  left center;
background-image: url('psplus.svg');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.eabadge
{
background-size:  15px;
background-position:  left center;
background-image: url('EA.svg');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.pagination {
  display: flex;
  justify-content: center;
}

.content-container {
  width: 40vw !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem !important;
    padding: .75rem 1.25rem;
   /* background-color: #ffffff;*/
    /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);*/
    margin-bottom: 30px;
}

.content-container-gameinfo {
    margin-left: auto;
    margin-right: auto;
    border-radius: .5rem .5rem .5rem .5rem !important;
    padding: .75rem 1.25rem;
    background-color: #ffffff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
    margin-bottom: 30px;
}

.card-header-custom {
  display: flex;
  font-size: 0.9em;
  /*background-color: #e70009;*/
  /*border: 1px solid #e5e5e5;*/
  margin-top: 5px;
  margin-bottom: 10px;
}


.card-footer-custom {
  font-size: 0.9em;
  /*background-color: #e70009;*/
  /*border: 1px solid #e5e5e5;*/
  margin-top: 10px;
  margin-bottom: 10px;
}

.linedividet {
    margin-top:  5px;
}

.linedivideb {
    margin-top:  5px;
    margin-bottom: 10px;
}

.opencritic-logo {
background-size:  15px;
background-position:  left center;
background-image: url('OpenCritic_logo.svg');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.opencritic-logo2 {
background-size:  15px;
background-position:  left center;
background-image: url('download.gif');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 0px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.rcorners2 {
  border-radius: 0.5rem;
  border: 2px solid;
  border-color: rgb(63 205 13);
  
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.hltb-logo {
background-size:  15px;
background-position:  left center;
background-image: url('hltb.svg');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.usregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('US.svg');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.sgregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/SG.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.hkregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/HK.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.trregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/TR.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}


.arregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/AR.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.auregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/AU.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.brregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/BR.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.caregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/CA.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.clregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/CL.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.coregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/CO.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.deregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/DE.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.euregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/EU.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.jpregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/JP.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.mxregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/MX.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.noregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/NO.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.peregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/PE.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.phregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/PH.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.plregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/PL.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.ruregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/RU.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.zaregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/ZA.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.krregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/KR.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.seregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/SE.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.chregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/CH.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.gbregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/GB.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.fire-logo {
background-size:  20px;
background-position:  left center;
background-image: url('medals/fire.png');
background-repeat: no-repeat;
margin-left: 10px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.gold-medal-logo {
background-size:  20px;
background-position:  left center;
background-image: url('medals/gold-medal.png');
background-repeat: no-repeat;
margin-left: 10px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.silver-medal-logo {
background-size:  20px;
background-position:  left center;
background-image: url('medals/silver-medal.png');
background-repeat: no-repeat;
margin-left: 10px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.bronze-medal-logo {
background-size:  20px;
background-position:  left center;
background-image: url('medals/bronze-medal.png');
background-repeat: no-repeat;
margin-left: 10px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.blank-medal-logo {
background-size:  20px;
background-position:  left center;
background-image: url('medals/blank-medal.png');
background-repeat: no-repeat;
margin-left: 10px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.shopee-svg {
background-size:  15px;
background-position:  left center;
background-image: url('ShopeeIcon.svg');
background-repeat: no-repeat;
margin-left: 0;
padding-left: 20px;  
display: inline-block;  
}

.price-container-in {
  display: flex;
  align-items: center; /* Vertically center the items */
  width: 100%; /* Full width of the cell */
}

.price {
  flex-grow: 1; /* This ensures that the price will take up as much space as possible, centering it */
  text-align: center; /* Centers the price */
}

.seagm-logo {
  border: 0;
  width: 14px;  /* Adjust width */
  height: 17px; /* Adjust height */
  margin-left: 10px;    /* Remove any default margin */
  padding: 0;   /* Remove any default padding */
  display: block; /* Make the image a block element */
}

.modal-link {
  color: red;
  font-weight: bold;
}

.coda-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/coda.png');
background-repeat: no-repeat;
/*margin-left: 10px;*/
/*padding-left: 20px;  /* width of the image plus a little extra padding */

display: inline-block;  /* may not need this, but I've found I do */
}

.shopee-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/shopee.png');
background-repeat: no-repeat;
/*margin-left: 10px;*/
/*padding-left: 20px;  /* width of the image plus a little extra padding */

display: inline-block;  /* may not need this, but I've found I do */
}

.lazada-logo {
background-size:  15px;
background-position:  left center;
background-image: url('flags/lazada.png');
background-repeat: no-repeat;
/*margin-left: 10px;*/
/*padding-left: 15px;  /* width of the image plus a little extra padding */

display: inline-block;  /* may not need this, but I've found I do */
}

.seagmsm-logo {
background-size: 15px;
background-position:  left center;
background-image: url('flags/seagm.png');
background-repeat: no-repeat;
/*margin-left: 10px;*/
/*padding-left: 20px;  /* width of the image plus a little extra padding */

display: inline-block;  /* may not need this, but I've found I do */
}


.mark-logo {
background-size: 20px;
background-position:  left center;
background-image: url('flags/check.png');
background-repeat: no-repeat;
/*margin-left: 10px;*/
/*padding-left: 20px;  /* width of the image plus a little extra padding */

display: inline-block;  /* may not need this, but I've found I do */
}

.vendors {
    padding-bottom: 0.5rem;
}




.phregion-logo {
background-size:  15px;
background-position:  left center;
background-image: url('philippines.png');
background-repeat: no-repeat;
margin-left: 5px;
padding-left: 20px;  /* width of the image plus a little extra padding */
display: inline-block;  /* may not need this, but I've found I do */
}

.card-img-overlay {
  position: absolute ;
  top: -10px !important;
  left: -10px !important;
  right: -10px !important;
  z-index: 1 !important;
}


.card-img-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.additional-overlay {
    position: absolute;
    bottom: 5px; /* Adjust as needed */
    left: 5px; /* Adjust as needed */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1; /* Ensure the overlay is above the image */
    font-size: 0.7rem; /* Set the font size here */
    border: 1px solid white; /* Set the border width and style */
    border-radius: 5px; /* Set the border radius to make it rounded */
    border:1;
}

.additional-overlay-right {
    position: absolute;
    bottom: 5px;
    left: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1;
    font-size: 0.7rem; /* Set the font size here */
    border: 1px solid white; /* Set the border width and style */
    border-radius: 5px; /* Set the border radius to make it rounded */
    border:1;
}

/*.card-img-overlay {
    z-index: 0;
}*/

.opencritic-container{
  margin-left:  none;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  width: 50px;
  background: rgba(0%, 0%, 0%, 0.5); 
}

.opencritic-container2{
  margin-left:  none;
  /*border-radius: 5px;*/
  /*background-color: #f5f5f5;*/
  /*border: 1px solid #e5e5e5;*/
  width: 30px;
  /*background: rgba(0%, 0%, 0%, 0.5); */
}

.opencritic-container3{
  margin-left:  none;
  /*border-radius: 5px;*/
  /*background-color: #f5f5f5;*/
  /*border: 1px solid #e5e5e5;*/
  width: 35px;
  /*background: rgba(0%, 0%, 0%, 0.5); */
}


.searchbox {
  /*width: 40%;*/
  /*border-radius: 5rem;*/
  padding: 10px;
  border: 1px solid #55597d;
  /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);*/
  margin:  10px;
  outline: none;

}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:none !important;
    -webkit-appearance:none;
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
   box-shadow: none !important; 
}

.btn-check:focus+.btn, .btn:focus {
   box-shadow: none !important; 
}

::-webkit-input-placeholder {
	font-size: 15px;
	font-family: 'Font Awesome 5 Free', 'Cairo';
	color: #55597d ;
}



.dropdown-style{
    font-family: 'Cairo';
  /*margin-left:  0px !important;*/
  background-color: white !important;
  color: #777 !important;
  border: 1px solid #e5e5e5 !important;
  border-radius:  5px !important;
  padding: 12px !important;
  width: 100% !important;
     /*box-shadow: 0 0 8px 0px rgb(0 0 0 / 10%);*/
}

.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
}

.dropdown-item{
  font-size: 14px !important;
}



.dropdown-toggle::after {
    -ms-transform: rotate(-0deg); /* IE 9 */
    -webkit-transform: rotate(-0deg); /* Chrome, Safari, Opera */
    transform: rotate(-0deg);
    transform:  translate(0, 200%);
    float:right;
}



.col-style {
  padding: 0 !important; 

}

.card-img{
  border-radius: 5px !important;
}

.card-title{
  font-size: 16px !important;
}

 .card-text{
  font-size:14px !important;
  /*font-weight: bold;*/
}

 .score-text{
  font-size:14px !important;
  color: white;
  font-weight: bold;
}


.nbadges {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-image: url('platform-icons18.webp');
  background-repeat: no-repeat;
  background-size: 30px;
  display: inline-block;
  flex: none;
}

.nbadges.nintendo {
  background-color: #e70009;
  background-position: 0px -510px;
}

.pbadges {
    width: 31px;
    height: 31px;
    border-radius: 100%;
    background-image: url('platform-icons18.webp');
    background-repeat: no-repeat;
    background-size: 35px;
    display: inline-block;
    flex: none;
}


.pbadges.playstation {
    background-color: black;
    background-position: -7px -5px;
}

.pbadges.playstation1 {
    background-color: blue;
    background-position: -3px -298px;
}

.nbadges.nintendo1 {
    background-color: black;
   background-position: 0px -510px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


@media screen and (max-width: 575.98px) {


.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 56vw;
  width: 100vw;
    left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

}

.suggest-text {
    display: none;
}

/*.shopee-logo {

    background-size:  75px;
}*/

.vendors {
    flex-wrap: nowrap !important;
}


.nopaddingA {
    padding-left:  0.1rem !important;
    padding-right:  0.5rem !important;
    padding-bottom: 0.1rem !important;
    padding-top: 0.1rem !important;
    display: block;
}

.coda-logo {
    background-position: center;
    background-size:  15px;
    padding: 2px;
}

.shopee-logo {
    background-position: center;
    background-size:  15px;
    padding: 2px;
}

.lazada-logo {
    background-position: center;
    background-size:  15px;
    padding: 2px;
}

.seagmsm-logo {
    background-position: center;
    background-size:  15px;
    padding: 2px;
}


.item-price-table {
    margin-left: -4px;
    margin-right: -4px;
    width: initial;
    max-width: initial;
    min-width: 100%;
}

.item-price-table td.version {
    font-size: 80%;
    /*border: none;*/
}

.logonin {
    max-width: 5.5em !important;
}

.price-container {
  width:  100%;
}



}




@media screen and (max-width: 1200px) {
  .custom-container {
    width: 95% !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem !important;
    padding: .75rem 1.25rem;
    background-color: #ffffff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
    margin-bottom: 30px;
  }

  p {
   display:block !important;
}



.ads {
   height: 250px;
   /*background-size: 100%;*/
   background-position: center;
   background-image: url('https://img.involve.asia/rpss/campaigns_banners/1610939924-FZPKAwTqQ6xBoehAv2u5AkGNXW54KWbL.jpg');
   background-repeat: no-repeat;
   display: block;
}

  .content-container {
  width: 95% !important;
}

.card-header-custom {
  display: flex;
  font-size: 0.8em;
  /*background-color: #e70009;*/
  /*border: 1px solid #e5e5e5;*/
  margin-top: 5px;
  margin-bottom: 10px;
}

.card-footer-custom {
  font-size: 0.8em;
  /*background-color: #e70009;*/
  /*border: 1px solid #e5e5e5;*/
  margin-top: 5px;
  margin-bottom: 10px;
}
/*  .nbadges{
    width: 20px;
    height: 20px;
    background-size: 20px;
  }

  .nbadges.nintendo {
    background-position:  0px -340px;
  }
*/




  body {
  width: 100%;
  margin: 0;
  padding: 0;
-webkit-overflow-scrolling: touch;
    padding-top: 80px !important;
      background-color:  #6767ab !important;

  }

  .searchbox {
    width: 80%;
  }

  .card-title{
    font-size:0.9rem !important;
  }

  .card-text{
    font-size:0.8rem !important;
  }

  .dropdown-style{
    font-size: 12px !important;
  }

  .dropdown-item{
  font-size: 12px !important;
  }

  .score-text{
  font-size:11px !important;
  }
}



